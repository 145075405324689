import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/_services/authentication-service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { servicesVersion } from 'typescript';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {
  isLoginError: boolean = false;
  returnUrl: string;
  loading = false;
  error = '';

  constructor(private authService: AuthenticationService, private router: Router, private route: ActivatedRoute,) {

    if (this.authService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  OnSubmit(userName, password) {

    this.authService.login(userName, password).pipe(first())
      .subscribe(
        data => {
          this.router.navigate([this.returnUrl]);
        },
        error => {
          this.error = error;
          this.loading = false;
        });
    //  .subscribe((data: any) => {
    //  localStorage.setItem('userToken', data.token);
    //  this.Service.isLoggedIn = true;
    //  this.router.navigate(['/account']);
    //},
    //  (err: HttpErrorResponse) => {
    //    this.isLoginError = true;
    //  });
  }

}
