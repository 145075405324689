import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControlOptions, AbstractControl, FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SharedDataService } from '@app/_services/shared-data.service'

@Component({
  selector: 'app-add-edit-application-users',
  templateUrl: './add-edit-application-users.component.html',
  styleUrls: ['./add-edit-application-users.component.css']
})
export class AddEditApplicationUsersComponent implements OnInit {

  userForm = new FormGroup({
    ApplicationUserId: new FormControl(''),
    ApplicationUserEmail: new FormControl('', [
      Validators.required,
      Validators.minLength(5),
      Validators.maxLength(250),
      Validators.email]),
    passwords: this.formBuilder.group({
      UserPassword: ['', [
        Validators.required,
        Validators.minLength(5)]],
      UserConfirmPassword: ['', [Validators.required]],
    }, { validator: this.passwordConfirming }),
   
    UserFirstName: new FormControl('',[
      Validators.required,
      Validators.maxLength(50)
      ]),
    UserSurname: new FormControl('', [
      Validators.required,
      Validators.maxLength(50)
    ]),
    IsAdmin: new FormControl(),
    IsActive: new FormControl(''),
   
  });

  constructor(private service: SharedDataService, private formBuilder: FormBuilder) { }

  @Input() applicationUser: any;
  @Output() formSaved = new EventEmitter<boolean>();  

  ngOnInit() {
    this.userForm.get('ApplicationUserId').setValue(this.applicationUser.ApplicationUserId);
    this.userForm.get('ApplicationUserEmail').setValue(this.applicationUser.ApplicationUserEmail);
    this.userForm.get(['passwords', 'UserPassword']).setValue(this.applicationUser.UserPassword);
    this.userForm.get(['passwords', 'UserConfirmPassword']).setValue(this.applicationUser.UserConfirmPassword);
    this.userForm.get('UserFirstName').setValue(this.applicationUser.UserFirstName);
    this.userForm.get('UserSurname').setValue(this.applicationUser.UserSurname);
    this.userForm.get('IsAdmin').setValue(this.applicationUser.IsAdmin);
    this.userForm.get('IsActive').setValue(this.applicationUser.IsActive);   
  }

  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.get('UserPassword').value !== c.get('UserConfirmPassword').value) {
      return { invalid: true };
    }
  }

  onSubmit() {
    // TODO: Use EventEmitter with form value
    
    console.warn(this.userForm.value);
  }

  addApplicationUser() {

    if (this.userForm.valid) {

      var newApplicationUser = {
        ApplicationUserId: "NA",
        ApplicationUserEmail: this.userForm.get('ApplicationUserEmail').value,
      UserPassword: this.userForm.get(['passwords', 'UserPassword']).value,
        UserFirstName: this.userForm.get('UserFirstName').value,
        UserSurname: this.userForm.get('UserSurname').value,
        IsAdmin: this.userForm.get('IsAdmin').value,
        IsActive: true
      };

      this.service.addApplicationUser(newApplicationUser).subscribe(res => {
        this.formSaved.emit(true);
      });
    }
  }

  updateApplicationUser() {

    this.userForm.get(['passwords', 'UserPassword']).setValue("NotSent");
    this.userForm.get(['passwords', 'UserConfirmPassword']).setValue("NotSent");

    if (this.userForm.valid) {

      var updateApplicationUser = {
        ApplicationUserId: this.userForm.get('ApplicationUserId').value,
        ApplicationUserEmail: this.userForm.get('ApplicationUserEmail').value,
        UserFirstName: this.userForm.get('UserFirstName').value,
        UserSurname: this.userForm.get('UserSurname').value,
        IsAdmin: this.userForm.get('IsAdmin').value,
        IsActive: this.userForm.get('IsActive').value,
        UserPassword: "NotSent"
      };

      this.service.updateApplicationUser(updateApplicationUser).subscribe(res => {
        this.formSaved.emit(true);
      });
    }
  }

}
