import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { AbstractControlOptions, AbstractControl, FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '@app/_services/authentication-service';
import { SharedDataService } from '@app/_services/shared-data.service'

@Component({
  selector: 'app-add-edit-account',
  templateUrl: './add-edit-account.component.html',
  styleUrls: ['./add-edit-account.component.css']
})
export class AddEditAccountComponent implements OnInit {

  accountForm = new FormGroup({
    BinanceAccountId: new FormControl(''),
    BinanceAccountName: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(100)]),
    APIKey: new FormControl('', [
      Validators.required,
      Validators.maxLength(250)
    ]),
    APISecret: new FormControl('', [
      Validators.required,
      Validators.maxLength(250)
    ]),
    InitialInvestment: new FormControl(''),
    InitalInvestmentDate: new FormControl(''),
    BinanceAccountIsActive: new FormControl(''),
    

  });

  constructor(private service: SharedDataService, private authService: AuthenticationService) { }

  @Input() account: any;
  @Output() formSaved = new EventEmitter<boolean>();
  @Output() formAccountReactivated = new EventEmitter<boolean>();

 

  ngOnInit() {

    var initialDate = this.setDateOffSet(new Date(this.account.InitalInvestmentDate));
    this.accountForm.get('BinanceAccountId').setValue(this.account.BinanceAccountId);
    this.accountForm.get('BinanceAccountName').setValue(this.account.BinanceAccountName);
    this.accountForm.get('APIKey').setValue(this.account.APIKey);
    this.accountForm.get('APISecret').setValue(this.account.APISecret);
    this.accountForm.get('InitalInvestmentDate').setValue(initialDate);
    this.accountForm.get('InitialInvestment').setValue(this.account.InitialInvestment);
    this.accountForm.get('BinanceAccountIsActive').setValue(this.account.BinanceAccountIsActive); 
    
  }

  setDateOffSet(timestamp) {
    const date = new Date(+timestamp);
    const offset = date.getTimezoneOffset();
    if (offset < 0) {
      date.setHours(12, 0, 0);
    }
    return date.toISOString().substring(0, 10);
  }


  addAccount() {
    var newAccount = {
      BinanceAccountId: 0,
      BinanceAccountName: this.accountForm.get('BinanceAccountName').value,
      APIKey: this.accountForm.get('APIKey').value,
      APISecret: this.accountForm.get('APISecret').value,
      InitalInvestmentDate: this.accountForm.get('InitalInvestmentDate').value,
      InitialInvestment: this.accountForm.get('InitialInvestment').value,
      BinanceAccountIsActive: this.accountForm.get('BinanceAccountIsActive').value,
      ApplicationUserId: this.authService.currentUserValue.id
    };

    this.service.addBinanceAccount(newAccount).subscribe(res => {
      this.formSaved.emit(true);
    });
  }

  updateAccount() {
    var newAccount = {
      BinanceAccountId: this.accountForm.get('BinanceAccountId').value,
      BinanceAccountName: this.accountForm.get('BinanceAccountName').value,
      APIKey: "NotSet",
      APISecret: "NotSet",
      InitalInvestmentDate: this.accountForm.get('InitalInvestmentDate').value,
      InitialInvestment: this.accountForm.get('InitialInvestment').value,
      BinanceAccountIsActive: this.accountForm.get('BinanceAccountIsActive').value,
    };

    this.service.updateBinanceAccount(newAccount).subscribe(res => {
      this.formSaved.emit(true);
    });
  }

  reActivateAccount() {
    this.service.reactivateBinanceAccount(this.accountForm.get('BinanceAccountId').value).subscribe(data => {
      this.formAccountReactivated.emit(true);
    });
  }
}
