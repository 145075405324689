import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SharedDataService } from '@app/_services/shared-data.service'

@Component({
  selector: 'app-show-application-users',
  templateUrl: './show-application-users.component.html',
  styleUrls: ['./show-application-users.component.css']
})
export class ShowApplicationUsersComponent implements OnInit {

  public applicationUsers: any[];
  ModalTitle: string;
  ActivateAddEditApplicationUserComp: boolean;
  applicationUser: any;

  constructor(private service: SharedDataService) { }

  @ViewChild('closeAddEditApplicationUserModal', { static: false }) closeAddEditApplicationUserModal: ElementRef;

  ngOnInit() {
    this.refreshUsersList();
  }

  refreshUsersList() {
    this.service.getApplicationUserList().subscribe(data => {
      this.applicationUsers = data;
    });
  }

  addClick() {


    this.applicationUser = {
      ApplicationUserId: "0",
      ApplicationUserEmail: "",
      UserPassword: "",
      UserConfirmPassword: "",
      UserFirstName: "",
      UserSurname: "",
      IsActive: true,
      IsAdmin: false,
    }

    this.ModalTitle = "Add Application User";
    this.ActivateAddEditApplicationUserComp = true;

  }

  closeClick() {
    this.ActivateAddEditApplicationUserComp = false;
    this.refreshUsersList();
  }

  onModalSaved(saved: boolean) {
    this.ActivateAddEditApplicationUserComp = false;
    this.closeAddEditApplicationUserModal.nativeElement.click();
    this.refreshUsersList();
  }

  public editApplicationUser(editApplicationUser) {
    this.applicationUser = {
      ApplicationUserEmail: editApplicationUser.applicationUserEmail,
      UserFirstName: editApplicationUser.userFirstName,
      UserSurname: editApplicationUser.userSurname,
      IsActive: editApplicationUser.isActive,
      IsAdmin: editApplicationUser.isAdmin,
      ApplicationUserId: editApplicationUser.applicationUserId,
      
      };

    this.ModalTitle = "Edit Application User";
    this.ActivateAddEditApplicationUserComp = true;
  }

}
