import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { formatDate } from '@angular/common';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router'
import { SharedDataService } from '@app/_services/shared-data.service'
import { AuthenticationService } from '@app/_services/authentication-service';

@Component({
  selector: 'app-show-binance-accounts',
  templateUrl: './show-binance-accounts.component.html',
  styleUrls: ['./show-binance-accounts.component.css']
})
export class ShowBinanceAccountsComponent implements OnInit {

  public accounts: any[];
  ModalTitle: string;
  TestApiModalTitle: string;
  DeleteAccountModalTitle: string;
  DeleteAction: string;
  DeleteAccountName: string;
  ActivateAddEditAccountComp: boolean;
  ActivateTestApiComp: boolean;
  ActivateDeleteAccountComp: boolean;
  account: any;
  selectedBinanceAccountId: number;
  showInactive: boolean;

  constructor(private service: SharedDataService, private authService: AuthenticationService, private route: ActivatedRoute,private router: Router) { }

  @ViewChild('closeAddEditAccountModal', { static: false }) closeAddEditAccountModal: ElementRef;
  @ViewChild('closetestApiModal', { static: false }) closetestApiModal: ElementRef;
  @ViewChild('closedeleteAccountModal', { static: false }) closedeleteAccountModal: ElementRef;
  @ViewChild('closedeleteConfirmationModal', { static: false }) closedeleteConfirmationModal: ElementRef;
  
  

  ngOnInit() {
    this.refreshAccountsList();
  }

  refreshAccountsList() {

    this.accounts = null;
    this.service.getBinanceAccountList(this.authService.currentUserValue.id).subscribe(data => {
      this.accounts = data;
    });
  }

  addClick() {
    this.account = {
      BinanceAccountId: 0,
      BinanceAccountName: "",
      APIKey: "",
      APISecret: "",
      InitalInvestmentDate: formatDate(new Date(), 'yyyy/MM/dd', 'en'),
      InitialInvestment: "",
      BinanceAccountIsActive: true
    }
   
    this.ModalTitle = "Add Binance Account";
    this.ActivateAddEditAccountComp = true;

  }

  closeClick() {
    this.ActivateAddEditAccountComp = false;
    this.refreshAccountsList();
  }

  testApiModalCloseClick() {
    this.ActivateTestApiComp = false;
  }

  deleteAccountCloseClick() {
    this.ActivateDeleteAccountComp = false;
  }

  onModalSaved(saved: boolean) {
    this.ActivateAddEditAccountComp = false;
    this.closeAddEditAccountModal.nativeElement.click();
    this.refreshAccountsList();
  }

  onModalAccountReactivated(reactivated: boolean) {
    this.ActivateAddEditAccountComp = false;
    this.closeAddEditAccountModal.nativeElement.click();
    this.refreshAccountsList();
  }

  onTestApiModalClosed(saved: boolean) {
    this.ActivateTestApiComp = false;
    this.closetestApiModal.nativeElement.click();
   
  }

  onDeleteModalClosed(close: boolean) {
    this.ActivateDeleteAccountComp = false;
    this.closedeleteAccountModal.nativeElement.click();
  }

  onModalDeleteSelected(account: any){
  this.ActivateDeleteAccountComp = false;
    this.closedeleteAccountModal.nativeElement.click();

    this.DeleteAction = "Delete";
    this.DeleteAccountName = account.BinanceAccountName;
    this.selectedBinanceAccountId = account.BinanceAccountId
    document.getElementById("openConfirmationModalButton").click();
  }

  onModalDeactivateSelected(account: any) {
    this.ActivateDeleteAccountComp = false;
    this.closedeleteAccountModal.nativeElement.click();

    this.DeleteAction = "Deactivate";
    this.DeleteAccountName = account.BinanceAccountName;
    this.selectedBinanceAccountId = account.BinanceAccountId
    document.getElementById("openConfirmationModalButton").click();
  }

  cancelDeleteAction() {

    this.closedeleteConfirmationModal.nativeElement.click();
  }

  confirmDeleteAction(deleteAction) {
    if (deleteAction == "Delete") {
     this.service.deleteBinanceAccount(this.selectedBinanceAccountId).subscribe(data => {
        this.closedeleteConfirmationModal.nativeElement.click();
        this.refreshAccountsList();
      });
    }

    if (deleteAction == "Deactivate") {
      this.service.deactivateBinanceAccount(this.selectedBinanceAccountId).subscribe(data => {
        this.closedeleteConfirmationModal.nativeElement.click();
        this.refreshAccountsList();
      });
    }

   // 
  }

  toggleInactive() {
    this.showInactive = !this.showInactive;
  }

  public editBinanceAccount(editAccount) {

    console.log(editAccount);

    this.account = {
      BinanceAccountId: editAccount.binanceAccountId,
      BinanceAccountName: editAccount.binanceAccountName,
      APIKey: "",
      APISecret: "",
      InitalInvestmentDate: editAccount.initalInvestmentDate,
      InitialInvestment: editAccount.initialInvestment,
      BinanceAccountIsActive: editAccount.binanceAccountIsActive
    };

    this.ModalTitle = "Edit Binance Account";
    this.ActivateAddEditAccountComp = true;
  }

  public testBinanceAccount(testAccount) {
    this.account = {
      BinanceAccountId: testAccount.binanceAccountId,
      BinanceAccountName: testAccount.binanceAccountName,
      APIKey: "",
      APISecret: "",
      InitalInvestmentDate: testAccount.initalInvestmentDate,
      InitialInvestment: testAccount.initialInvestment,
      BinanceAccountIsActive: testAccount.binanceAccountIsActive
    };

    this.TestApiModalTitle = "Test Account " + testAccount.binanceAccountName;
    this.ActivateTestApiComp = true;
  }

  public deleteBinanceAccount(delAccount) {
    this.account = {
      BinanceAccountId: delAccount.binanceAccountId,
      BinanceAccountName: delAccount.binanceAccountName,
      APIKey: "",
      APISecret: "",
      InitalInvestmentDate: delAccount.initalInvestmentDate,
      InitialInvestment: delAccount.initialInvestment,
      BinanceAccountIsActive: delAccount.binanceAccountIsActive
    };

    this.DeleteAccountModalTitle = "Delete Account: " + delAccount.binanceAccountName;
    this.ActivateDeleteAccountComp = true;
  }
  
  viewBinanceAccountHistories(selAccount) {
    this.service.selectedAccount = selAccount;
    this.router.navigate(['/histories',  selAccount.binanceAccountId, selAccount.binanceAccountName ], { relativeTo: this.route });
  }
}
