import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ChartsModule } from 'ng2-charts';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { BinanceAccountComponent } from './binance-account/binance-account.component';
import { AddEditAccountComponent } from './binance-account/add-edit-account/add-edit-account.component';
import { SharedDataService } from '@app/_services/shared-data.service'
import { ShowBinanceAccountsComponent } from './binance-account/show-binance-accounts/show-binance-accounts.component';
import { UserComponent } from './user/user.component';
import { SignInComponent } from './user/sign-in/sign-in.component';
import { BinanceAccountHistoryComponent } from './binance-account-history/binance-account-history.component';
import { ApplicationUserComponent } from './application-user/application-user.component';
import { ShowApplicationUsersComponent } from './application-user/show-application-users/show-application-users.component';
import { AddEditApplicationUsersComponent } from './application-user/add-edit-application-users/add-edit-application-users.component';

import { ErrorInterceptor } from './_helpers/error.interceptor';
import { AuthGuard } from './_helpers/auth.guard';
import { Role } from './_models/role';
import { TestApiComponent } from './binance-account/test-api/test-api.component';
import { DeleteAccountComponent } from './binance-account/delete-account/delete-account.component';
import { DeleteApplicationUserComponent } from './application-user/delete-application-user/delete-application-user.component';


@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    BinanceAccountComponent,
    AddEditAccountComponent,
    ShowBinanceAccountsComponent,
    UserComponent,
    SignInComponent,
    BinanceAccountHistoryComponent,
    ApplicationUserComponent,
    ShowApplicationUsersComponent,
    AddEditApplicationUsersComponent,
    TestApiComponent,
    DeleteAccountComponent,
    DeleteApplicationUserComponent

  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    ChartsModule,
    RouterModule.forRoot([
      {
        path: 'login', component: UserComponent,
        children: [{ path: '', component: SignInComponent }]
      },
      { path: '', component: HomeComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'account', component: BinanceAccountComponent, canActivate: [AuthGuard] },
      { path: 'histories/:id1/:id2', component: BinanceAccountHistoryComponent, canActivate: [AuthGuard] },
      {
        path: 'application-users', component: ApplicationUserComponent, canActivate: [AuthGuard],
        data: { roles: [Role.Admin] }
      },
      { path: '', redirectTo: '/login', pathMatch: 'full' }
    ])
  ],
  providers: [SharedDataService
    , { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
