import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { AbstractControlOptions, AbstractControl, FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SharedDataService } from '@app/_services/shared-data.service'

@Component({
  selector: 'app-test-api',
  templateUrl: './test-api.component.html',
  styleUrls: ['./test-api.component.css']
})
export class TestApiComponent implements OnInit {

  constructor(private service: SharedDataService) { }

  @Input() account: any;
  @Output() formClosed = new EventEmitter<boolean>();
  isLoading: boolean = true;
  testResponse: any;
  apiConnectSuccess: boolean = false;

  ngOnInit() {

    this.isLoading = true;
    this.service.testBinanceAccount(this.account.BinanceAccountId).subscribe(data => {
      this.isLoading = false;
     
      this.testResponse = data;
      if (this.testResponse.success == true) {
        this.apiConnectSuccess = true;
      }
      else {
        this.apiConnectSuccess = false;
      }
    });
  }

}
