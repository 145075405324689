import { Component } from '@angular/core';
import { AuthenticationService } from '@app/_services/authentication-service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent {

  constructor(private authService: AuthenticationService) {

  }

  getUserFirstName() {
   return this.authService.currentUserValue.firstName;
  }
}
