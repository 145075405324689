import { Component, OnInit, Input } from '@angular/core';
import {DatePipe, DecimalPipe } from '@angular/common'
import { SharedDataService } from '@app/_services/shared-data.service'
import { ActivatedRoute } from '@angular/router'
import { map } from 'rxjs/operators';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-binance-account-history',
  templateUrl: './binance-account-history.component.html',
  styleUrls: ['./binance-account-history.component.css'],
  providers: [DatePipe]
})
export class BinanceAccountHistoryComponent implements OnInit {

  constructor(private service: SharedDataService, private route: ActivatedRoute, private datePipe: DatePipe) { }

  @Input() account: any;
  public histories: any[];
  public selectedBinanceAccount: any;
  public strFromDate: string;
  public strToDate: string;

  public fromDate: Date;
  public toDate: Date;
  public lastMonthStart: Date;
  public lastMonthEnd: Date;
  public strLastMonthName: string;
  BinanceAccountId: number;
  BinanceAccountName: string;
  public overallAccountPerformance: any;
  public datePeriodAccountPerformance: any;
  public lastMonthAccountPerformance: any;

  public chartsReady = false;
  public barChartLegend = true;
  public barChartType = 'bar';
  public lineChartType = 'line';
  public barChartPlugins = [];
  public lineChartPlugins = [];
  public barChartOptions: (ChartOptions & { annotation?: any }) = {
    responsive: true,
    
  };

  public barChartColors: Color[] = [
    { backgroundColor: '#1b6ec2' },
    { backgroundColor: 'rgba(255,0,0,0.3)' },
  ]

  public calculatedProfitBarChartData: ChartDataSets[];
  public cumulativeROILineChartData: ChartDataSets[];
  public cumulativeROIPercentLineChartData: ChartDataSets[];
  public walletBalanceChartData: ChartDataSets[];
  public dateChartLabels: Label[] = [];



  ngOnInit() {

    this.fromDate = this.setDateOffSet(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    this.toDate = this.setDateOffSet(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));
    this.lastMonthStart = this.setDateOffSet(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    this.lastMonthStart.setMonth(this.fromDate.getMonth() -1)
    this.lastMonthEnd = this.setDateOffSet(new Date(new Date().getFullYear(), new Date().getMonth(), 0));
    this.lastMonthEnd.setDate(this.toDate.getDate() - 1)

    this.strFromDate = this.fromDate.toISOString().slice(0, 16);
    this.strToDate = this.toDate.toISOString().slice(0, 16);
    this.strLastMonthName = this.datePipe.transform(this.lastMonthStart, 'MMMM');

    this.route.params.subscribe(params => {
      this.BinanceAccountId = params['id1'];
      this.BinanceAccountName = params['id2'];
      this.refreshHistoryList();
      this.getOverallAccountPerformanceData();
      this.getDatePeriodAccountPerformanceData();
      this.getLastMonthAccountPerformanceData();
    });

   

    this.selectedBinanceAccount = this.service.selectedAccount;
    console.log(this.BinanceAccountId);


  }

  refreshHistoryList() {
    this.service.getAccountHistoryByAccountList(this.BinanceAccountId, this.datePipe.transform(this.fromDate, 'yyyy-MM-dd'), this.datePipe.transform(this.toDate, 'yyyy-MM-dd')).subscribe(data => {
      this.histories = data;

      var calculatedProfitValues = [];
      var cumulativeROIPercentValues = [];
      var cumulativeROIValues = [];
      var walletBalanceValues = [];
      var labels = []

      for (let item of data) {

        calculatedProfitValues.push(item.calculatedProfit);
        cumulativeROIPercentValues.push(item.cumulativeDailyReturnOnIvestment)
        cumulativeROIValues.push(item.cumulativeCalculatedProfit)
        walletBalanceValues.push(item.snapShotCloseAmount)
        labels.push(this.datePipe.transform(item.snapShotDate, 'dd MMM yy'));
      }

      this.calculatedProfitBarChartData = [
        {
          data: calculatedProfitValues.reverse()
          , label: "Daily ROI $"
        },
      ];

      this.cumulativeROIPercentLineChartData = [
        {
          data: cumulativeROIPercentValues.reverse()
          , label: "Cumulative ROI %"
        },
      ];

      this.cumulativeROILineChartData = [
        {
          data: cumulativeROIValues.reverse()
          , label: "Cumulative ROI $"
        },
      ];

      this.walletBalanceChartData = [
        {
          data: walletBalanceValues.reverse()
          , label: "Wallet Balance"
        },
      ];
      
      

      this.dateChartLabels = labels.reverse();

      this.chartsReady = true;
    });


  }

  getOverallAccountPerformanceData() {
    this.service.getAccountPerformanceForPeriod(this.BinanceAccountId, '01 Jan 1980', '31 Dec 2099').subscribe(data => {
      this.overallAccountPerformance = data;
      
    
    });
  }

  getDatePeriodAccountPerformanceData() {
    this.service.getAccountPerformanceForPeriod(this.BinanceAccountId, this.datePipe.transform(this.fromDate, 'yyyy-MM-dd'), this.datePipe.transform(this.toDate, 'yyyy-MM-dd')).subscribe(data => {
      this.datePeriodAccountPerformance = data;

    });
  }

  getLastMonthAccountPerformanceData() {
    this.service.getAccountPerformanceForPeriod(this.BinanceAccountId, this.datePipe.transform(this.lastMonthStart, 'yyyy-MM-dd'), this.datePipe.transform(this.lastMonthEnd, 'yyyy-MM-dd')).subscribe(data => {
      this.lastMonthAccountPerformance = data;

    });
  }

  refreshDateRangePerformanceData() {

    this.datePeriodAccountPerformance = null;
    this.lastMonthAccountPerformance = null;

    this.fromDate = this.setDateOffSet(new Date(this.strFromDate));
    this.toDate = this.setDateOffSet(new Date(this.strToDate));
    this.lastMonthStart.setMonth(this.fromDate.getMonth() - 1);
    this.lastMonthEnd.setMonth(this.toDate.getMonth() - 1);
    this.getDatePeriodAccountPerformanceData();
    this.getLastMonthAccountPerformanceData();
    this.getOverallAccountPerformanceData();
    this.refreshHistoryList();
    this.strLastMonthName = this.datePipe.transform(this.lastMonthStart, 'MMMM');

    console.log(this.lastMonthStart);

  }

  setDateOffSet(timestamp) {
    const date = new Date(+timestamp);
    const offset = date.getTimezoneOffset();
    if (offset < 0) {
      date.setHours(12, 0, 0);
    }
    return date;
  }
}
