import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-binance-account',
  templateUrl: './binance-account.component.html',
  styleUrls: ['./binance-account.component.css']
})
export class BinanceAccountComponent implements OnInit {



  constructor() {
   
  }

  ngOnInit() {

  
  }

  

}



