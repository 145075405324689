import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { AuthenticationService } from '@app/_services/authentication-service';
import { SharedDataService } from '@app/_services/shared-data.service'

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.css']
})
export class DeleteAccountComponent implements OnInit {

  constructor(private service: SharedDataService, private authService: AuthenticationService) { }

  @Input() account: any;
  @Output() formDeleteSelected = new EventEmitter<boolean>();
  @Output() formDeactivateSelected = new EventEmitter<boolean>();
  @Output() formClosed = new EventEmitter<boolean>();
  

  ngOnInit(): void {
  }

  deleteAccount() {
    this.formDeleteSelected.emit(this.account);
  }

  deactivateAccount() {
    this.formDeactivateSelected.emit(this.account);
  }

  cancelDeleteAccount(){
    this.formClosed.emit(true);
  }
}
