import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SharedDataService } from '@app/_services/shared-data.service'
import { AuthenticationService } from '@app/_services/authentication-service';
import { Role } from '@app/_models/role';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
  isExpanded = false;


  constructor(private Service: SharedDataService, private authService: AuthenticationService, private router: Router) {
  
  }

  isLoggedIn() {
    return this.authService.currentUserValue != null;
  }

  isAdmin() {
    return this.authService.currentUserValue.role == Role.Admin;
  }


  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  Logout() {
    this.authService.logout();
  }
}
