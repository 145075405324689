import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '@app/_services/authentication-service'

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  private _baseUrl: string;
  public selectedAccount: any;


  constructor(private authService: AuthenticationService , private http: HttpClient, @Inject('BASE_URL') baseUrl: string, ) {
    this._baseUrl = baseUrl;
    
  }

  userAuthentication(userName, password) {

    var authData = {
      username: userName,
      password: password
    };
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
    return this.http.post(this._baseUrl + 'Account/login', authData, { headers: reqHeader });
  }

  getBinanceAccountList(val: any): Observable<any[]> {
    return this.http.get<any>(this._baseUrl + 'binanceaccount?applicationUserId=' + val, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authService.currentUserValue.token})});
  }

  addBinanceAccount(val: any) {
    return this.http.post(this._baseUrl + 'binanceaccount', val, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authService.currentUserValue.token}) });
  }

  updateBinanceAccount(val: any) {
    return this.http.put(this._baseUrl + 'binanceaccount', val, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authService.currentUserValue.token }) });
  }

  deleteBinanceAccount(val: any) {
    return this.http.delete(this._baseUrl + 'BinanceAccount/' + val, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authService.currentUserValue.token }) });
  }

  deactivateBinanceAccount(accountId: any) {
    //return this.http.get(this._baseUrl + 'binanceaccount/DeactivateAccount?binanceAccountId=' + val, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authService.currentUserValue.token }) });
    return this.http.get(this._baseUrl + 'binanceaccount/DeactivateAccount?binanceAccountId=' + accountId, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authService.currentUserValue.token }) });
  }

  reactivateBinanceAccount(val: any) {
    return this.http.get(this._baseUrl + 'binanceaccount/ReactivateAccount?binanceAccountId=' + val, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authService.currentUserValue.token }) });
  }

  testBinanceAccount(accountId: any) {
    return this.http.get(this._baseUrl + 'binanceaccount/TestAccountAPI?binanceAccountId=' + accountId, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authService.currentUserValue.token }) });
  }

  getAccountHistoryByAccountList(accountId, startDate, endDate): Observable<any[]> {
    return this.http.get<any>(this._baseUrl + 'BinanceAccountSnapShot/GetHistoryByAccount?binanceAccountId=' + accountId + '&StartDate=' + startDate + '&EndDate=' + endDate, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authService.currentUserValue.token }) });
  }

  getApplicationUserList(): Observable<any[]> {
    return this.http.get<any>(this._baseUrl + 'ApplicationUser', { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authService.currentUserValue.token }) });
  }

  addApplicationUser(val: any) {
    return this.http.post(this._baseUrl + 'ApplicationUser', val, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authService.currentUserValue.token }) });
  }

  updateApplicationUser(val: any) {
    return this.http.put(this._baseUrl + 'ApplicationUser', val, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authService.currentUserValue.token }) });
  }

  deleteApplicationUser(val: any) {
    return this.http.delete(this._baseUrl + 'ApplicationUser/' + val, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authService.currentUserValue.token }) });
  }

  getAccountPerformanceForPeriod(accountId, startDate, endDate): Observable<any> {
    return this.http.get<any>(this._baseUrl + 'BinanceAccountSnapShot/GetAccountPerformanceForPeriod?binanceAccountId=' + accountId + '&StartDate=' + startDate + '&EndDate=' + endDate, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.authService.currentUserValue.token }) });
  }
  
}
